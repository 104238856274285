import styled from 'styled-components';
import React from 'react';
import { Camera, Check } from '@styled-icons/fa-solid';

export const MAX_CHARACTERS = 50;

type Props = {
  className?: string;
  value: string;
  helpText?: string;
  photoCaptured?: boolean;
  chooseQuantity?: boolean;
};

export const AnswerLabel = ({ className, value, helpText, photoCaptured, chooseQuantity }: Props) => {
  const showPhotoCapturedIcon = photoCaptured && chooseQuantity !== true;
  const photoCapturedClass = showPhotoCapturedIcon ? 'photoCaptured' : '';
  const fullClassName = className ? `${className} ${photoCapturedClass}` : photoCapturedClass;
  return (
    <AnswerLabelContainer>
      <ButtonBodyContainer>
        <ButtonLabel className={fullClassName}>{value}</ButtonLabel>
        {showPhotoCapturedIcon ? (
          <IconContainer data-testid="icon-container">
            <CheckMarkIcon />
            <CameraIcon />
          </IconContainer>
        ) : null}
      </ButtonBodyContainer>

      {helpText ? <AnswerHelpText data-testid="answer-help-text">{helpText}</AnswerHelpText> : null}
    </AnswerLabelContainer>
  );
};

const AnswerLabelContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
`;

const AnswerHelpText = styled.p`
  padding-top: ${({ theme }) => theme.space.sm};
  margin: 0;
  font-size: ${({ theme }) => theme.typography.size.sm};
  text-align: center;
`;

const ButtonBodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ButtonLabel = styled.label`
  display: flex;
  flex: 1;
  justify-content: center;

  &.photoCaptured {
    margin-left: 51px;

  &.active {
    color: ${({ theme }) => theme.color.activeButtonText};
  }
`;
const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const CheckMarkIcon = styled(Check)`
  margin-left: ${({ theme }) => theme.space.lg};
  padding-bottom: ${({ theme }) => theme.space.sm};
  height: 10px;
  width: 10px;
`;

const CameraIcon = styled(Camera)`
  height: 14px;
  width: 18px;
`;
