import { HelpText } from '../../components/HelpText';
import { QuestionMessage } from '../../models/AppConfiguration';
import { AlertMessageDetails, QuestionState, TakePhotoDetails } from '../../models/AppState';
import { DamageGuides, DamageGuideSection, QEAnswer, QEQuestion } from '../../models/QEQuestion';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import {
  isSourceLocked,
  isAnswerRelatedToAnnouncement,
  isQuestionRelatedToAnnouncement,
  findIdentifiedBy,
  findParents,
  findChildren,
  getQuestionsByAnnouncementCode
} from '../../helpers/mappingHelpers';
import { Announcement } from '../../models/Announcement';
import { capitalize } from '../../helpers/capitalize';
import { AnswerNotes } from './AnswerNotes';
import { QuantityPicker } from './QuantityPicker';
import { AnswerButton } from '../../components/AnswerButton';
import { WorldState } from '../../helpers/computeWorldState';
import { DamageModal } from './DamageModal';
import { ExternalLinkAlt, InfoCircle } from '@styled-icons/fa-solid';
import { NestedAnswers } from './NestedAnswers';
import { handleAnswerChange, handleQuantityAnswerChange } from '../../helpers/handleAnswerChange';
import { TakePhotos } from './TakePhotos';
import { AnswerLabel } from './AnswerLabel';
import { getPhotoCaptured, getQuantity, showPhotoButton, handlePhotoButtonClick } from '../../helpers/takePhotoHelper';

const LINK_REGEX = /\[(.*?)\]\((.+?)\)/g;

type Props = {
  question: QEQuestion;
  onChange: (newQuestionResponses: QuestionState[]) => void;
  onLinkClick?: (url: string) => void;
  showAnsweredIndicator?: boolean;
  readonly?: boolean;
  announcementDictionary: Announcement[];
  showHelpText?: boolean;
  questionMessages?: QuestionMessage[];
  worldState: WorldState;
  damageGuides?: DamageGuides;
  setOverlayActive?: (overlayActive: boolean) => void;
  inlineImaging?: boolean;
  onPhotoButtonClick?: (takePhotos: TakePhotoDetails) => void;
  sendAlertMessage?: (alertMessages: AlertMessageDetails) => void;
  client: string;
};
export const Question = ({
  question,
  onChange,
  onLinkClick,
  showAnsweredIndicator = true,
  readonly = false,
  showHelpText = true,
  announcementDictionary,
  questionMessages,
  worldState,
  damageGuides,
  setOverlayActive,
  inlineImaging,
  onPhotoButtonClick,
  sendAlertMessage,
  client
}: Props) => {
  const [damageModal, setDamageModal] = useState<DamageGuideSection>();

  useEffect(() => {
    if (damageModal === undefined) {
      setOverlayActive?.(false);
    } else {
      setOverlayActive?.(true);
    }
  }, [damageModal]);

  const removedAnnouncements = worldState.announcements.filter(ann =>
    worldState.calculatedAnnouncements.some(ca => ca.code === ann.code && ca.value !== true)
  );

  const removedAnnouncementsForQuestionAndChildren = removedAnnouncements.filter(ann =>
    isQuestionRelatedToAnnouncement(question, ann.code, worldState.questionnaire)
  );

  const removedSourceLockedAnnouncements = removedAnnouncementsForQuestionAndChildren.filter(a =>
    isSourceLocked(worldState.announcerSource, a.source)
  );

  const removedAnnouncementsForJustThisQuestion = removedAnnouncementsForQuestionAndChildren.filter(
    removedAnnouncement =>
      question.answers.some(a => a.announcements?.includes(removedAnnouncement.code)) ||
      !findChildren(question, worldState.questionnaire).some(
        child =>
          worldState.isQuestionRequired(child) &&
          isQuestionRelatedToAnnouncement(child, removedAnnouncement.code, worldState.questionnaire)
      )
  );

  const questionIsValid = !removedAnnouncementsForJustThisQuestion.some(a =>
    removedSourceLockedAnnouncements.includes(a)
  );

  const identifiedBy = findIdentifiedBy(question, worldState.questionnaire, worldState.announcements);
  const selectedAnswers = worldState.answersForQuestion(question);

  const handleChange = (
    answer: QEAnswer,
    changedQuestion: QEQuestion,
    previousSelectedAnswers: QEAnswer[],
    parentAnswer?: QEAnswer,
    parentQuestion?: QEQuestion
  ) => {
    const previouslySelectedParentAnswers = parentAnswer ? selectedAnswers : undefined;
    const newResponses = handleAnswerChange(
      worldState.questionResponses,
      answer,
      changedQuestion,
      previousSelectedAnswers,
      parentAnswer,
      parentQuestion,
      sendAlertMessage,
      previouslySelectedParentAnswers
    );
    newResponses && onChange(newResponses);
  };

  const handleNoteChange = (answer: QEAnswer, newNote: string) => {
    const newResponses = worldState.questionResponses.map(qr =>
      qr.guid === question.guid
        ? { ...qr, answers: qr.answers.map(a => (a.value === answer.value ? { ...a, notes: newNote } : a)) }
        : qr
    );
    onChange(newResponses);
  };

  const handleQuantityChange = (answer: QEAnswer, quantity: number) => {
    const newResponses = handleQuantityAnswerChange(
      worldState.questionResponses,
      answer,
      quantity,
      question,
      selectedAnswers,
      sendAlertMessage
    );

    if (newResponses) {
      onChange(newResponses);
      return false;
    } else {
      // if newResponses is undefined, we are prompting to remove photos
      return true;
    }
  };

  const findQuestionResponse = (answer: QEAnswer) =>
    worldState.questionResponses.find(q => q.guid === question.guid)?.answers.find(qa => qa.value === answer.value);

  const questionMessage = questionMessages?.filter(
    qMessage =>
      qMessage.tags.length === question.tags?.length &&
      qMessage.tags.every(messageTag => question.tags?.includes(messageTag))
  )[0];

  const guideImageUrl = question.guideImages?.filter(g => g.tags?.some(tag => worldState.tags.includes(tag)))[0];

  const guideImageUrlWithNoTags =
    question.guideImages && !question.guideImages[0].hasOwnProperty('tags') ? question.guideImages[0] : undefined;

  // Finds any links in helpText. useMemo so we only do this once (not on every render).
  const links = useMemo(() => (question.helpText ? Array.from(question.helpText.matchAll(LINK_REGEX)) : undefined), [
    question.helpText
  ]);

  const questionAnswered = () => {
    if (showAnsweredIndicator && selectedAnswers.length > 0) {
      const allRequiredAreAnswered = selectedAnswers.map(ans => {
        if (!ans.nestedQuestions || ans.nestedQuestions.length === 0) {
          return true;
        }
        const nestedQuestionsAreAnswered = ans.nestedQuestions.map(guid => {
          const nestedQuestion = worldState.questionnaire.find(q => q.guid === guid);
          if (nestedQuestion === undefined) {
            return false;
          }
          const nestedQuestionAnswers = worldState.answersForQuestion(nestedQuestion);
          const questionHasAnswers = !!nestedQuestionAnswers.length;
          return !!questionHasAnswers;
        });

        return nestedQuestionsAreAnswered.every(value => value === true);
      });
      return allRequiredAreAnswered.every(value => value === true);
    } else {
      return false;
    }
  };

  const [answerOptions, setAnswerOptions] = useState<
    | {
        disabledByAnnouncement: boolean;
        disabled: boolean;
        answer: QEAnswer;
      }[]
    | undefined
  >();

  useEffect(() => {
    const options = question.answers.map(answer => {
      const disabledByAnnouncement = worldState.announcements.some(announcement => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (getQuestionsByAnnouncementCode(worldState.questionnaire)[announcement.code]?.length > 1) {
          return false;
        }
        if (
          !isSourceLocked(worldState.announcerSource, announcement.source) ||
          !isQuestionRelatedToAnnouncement(question, announcement.code, worldState.questionnaire) ||
          isAnswerRelatedToAnnouncement(answer, announcement.code, worldState.questionnaire)
        ) {
          return false;
        }

        const hasCommonGroupWithRelatedAnswer =
          question.type === 'multi' &&
          question.answers.some(
            a =>
              a !== answer &&
              isAnswerRelatedToAnnouncement(a, announcement.code, worldState.questionnaire) &&
              (a.answerGroups?.some(ag => answer.answerGroups?.includes(ag)) ||
                (!a.answerGroups?.length && !answer.answerGroups?.length))
          );

        // is another question answered in a way that agrees with this announcement?
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const otherMappedQuestionHasRelatedAnswer = worldState.questionnaire.some(otherQuestion => {
          if (
            otherQuestion === question ||
            findParents(question, worldState.questionnaire).includes(otherQuestion) ||
            findParents(otherQuestion, worldState.questionnaire).includes(question) ||
            !isQuestionRelatedToAnnouncement(otherQuestion, announcement.code, worldState.questionnaire) ||
            !worldState.isQuestionRequired(otherQuestion)
          ) {
            return false;
          }

          const answersForOtherQuestion = worldState.answersForQuestion(otherQuestion);
          return (
            answersForOtherQuestion.length === 0 ||
            answersForOtherQuestion.some(a =>
              isAnswerRelatedToAnnouncement(a, announcement.code, worldState.questionnaire)
            )
          );
        });

        return !otherMappedQuestionHasRelatedAnswer && !hasCommonGroupWithRelatedAnswer;
      });

      if (disabledByAnnouncement) {
        const response = findQuestionResponse(answer);
        if (response?.selectedByDefault) {
          handleChange(answer, question, selectedAnswers);
        }
      }

      return {
        disabledByAnnouncement,
        disabled: readonly || disabledByAnnouncement,
        answer
      };
    });
    setAnswerOptions(options);
  }, []);

  return (
    <Container
      data-testid="question-container"
      questionIsValid={questionIsValid}
      className={questionAnswered() ? 'answered' : undefined}
    >
      {damageModal && <DamageModal definition={damageModal} onClose={() => setDamageModal(undefined)} />}
      <QuestionText>{question.text}</QuestionText>
      {question.helpText ? (
        <QuestionHelpText data-testid="question-help-text">
          {links
            ? question.helpText
                .split(/\[.+?\]\(.+?\)/) // splits helpText by link occurences (also removes all links)
                .flatMap((substring, index) => {
                  // if no link follows the substring this will evaluate to empty array
                  const fullLinkThatFollowsSubstring = links![index] || [];
                  if (fullLinkThatFollowsSubstring.length === 0) {
                    return [substring];
                  }

                  // const links is an array of arrays. The arrays look like this:
                  // ['[Damage Guide](damageGuideType:testGuide)','Damage Guide','damageGuideType:testGuide']
                  // the following line will just give us the last 2 items in the array
                  const [linkText, linkTo] = fullLinkThatFollowsSubstring.slice(1);

                  return [
                    substring,
                    linkTo.startsWith('damageGuide') ? (
                      <StyledLink
                        key={index}
                        data-testid="damage-guide-link"
                        href={`#${linkTo}`}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          const damageModalKey = linkTo.split(':')[1];

                          setDamageModal(damageGuides?.[damageModalKey]);
                        }}
                      >
                        {linkText}
                      </StyledLink>
                    ) : (
                      <React.Fragment key={index}>
                        <StyledLink
                          data-testid="help-text-link"
                          href={linkTo}
                          onClick={e => {
                            if (!!onLinkClick) {
                              e.preventDefault();
                              e.stopPropagation();
                              onLinkClick(linkTo);
                            }
                          }}
                          target="blank"
                          rel="noreferer"
                        >
                          {linkText}
                        </StyledLink>
                        &nbsp;
                        <ExternalLinkAlt size={12} />
                      </React.Fragment>
                    )
                  ];
                })
            : question.helpText}
        </QuestionHelpText>
      ) : null}
      {questionMessage ? (
        <QuestionMessageContainer>
          <InfoImageDivContainer>
            <InfoImage></InfoImage>
          </InfoImageDivContainer>
          <QuestionMessageText data-testid="question-message-text">{questionMessage.message}</QuestionMessageText>
        </QuestionMessageContainer>
      ) : null}
      {guideImageUrl ? <img data-testid="guide-image" src={guideImageUrl.url} /> : null}
      {guideImageUrlWithNoTags ? (
        <img height="undefined" width="100%" data-testid="guide-image-no-tags" src={guideImageUrlWithNoTags.url} />
      ) : null}
      {question.type === 'multi' ? <MultiTip>Select all that apply</MultiTip> : null}
      <AnswerContainer data-testid="answers">
        {answerOptions?.map(answerOption => (
          <React.Fragment key={answerOption.answer.value}>
            {answerOption.answer.chooseQuantity ? (
              <QuantityPicker
                key={answerOption.answer.value}
                data-testid={`answer-${answerOption.answer.value}`}
                label={answerOption.answer.value}
                onChange={newQuantity => handleQuantityChange(answerOption.answer, newQuantity)}
                value={getQuantity(worldState, question, answerOption.answer)}
                maxQuantity={answerOption.answer.maxQuantity}
                disabled={answerOption.disabled}
                helpText={answerOption.answer.helpText}
                photoCaptured={getPhotoCaptured(worldState.questionResponses, question, answerOption.answer)}
                question={question}
                answer={answerOption.answer}
                sendAlertMessage={sendAlertMessage}
              />
            ) : (
              <React.Fragment>
                {answerOption.answer.nestedQuestions && answerOption.answer.nestedQuestions.length > 0 ? (
                  answerOption.answer.nestedQuestions.map((nestedGuid, index) => {
                    const nestedQuestion = worldState.questionnaire.find(q => q.guid === nestedGuid);
                    return nestedQuestion ? (
                      <NestedAnswers
                        key={index}
                        worldState={worldState}
                        answer={answerOption.answer}
                        question={question}
                        nestedQuestion={nestedQuestion}
                        nestedResponses={worldState.answersForQuestion(nestedQuestion)}
                        onChange={handleChange}
                        disabled={answerOption.disabled}
                        inlineImaging={inlineImaging}
                        onPhotoButtonClick={onPhotoButtonClick}
                      />
                    ) : null;
                  })
                ) : (
                  <AnswerButton
                    id={`${client}:${question.id}:${answerOption.answer.value.split(' ').join('')}`}
                    key={answerOption.answer.value}
                    data-testid={`answer-${answerOption.answer.value}`}
                    className={selectedAnswers.some(a => a === answerOption.answer) ? 'active' : ''}
                    disabled={answerOption.disabled}
                    onClick={() => handleChange(answerOption.answer, question, selectedAnswers)}
                  >
                    <AnswerLabel
                      className={selectedAnswers.some(a => a === answerOption.answer) ? 'active' : ''}
                      value={answerOption.answer.value}
                      helpText={answerOption.answer.helpText}
                      photoCaptured={getPhotoCaptured(worldState.questionResponses, question, answerOption.answer)}
                    />
                  </AnswerButton>
                )}
              </React.Fragment>
            )}
            {answerOption.answer.showNotes && selectedAnswers.some(a => a.value === answerOption.answer.value) && (
              <AnswerNotes
                data-testid={`answer-${answerOption.answer.notesLabel}`}
                label={answerOption.answer.notesLabel!}
                value={findQuestionResponse(answerOption.answer)?.notes}
                onChange={newValue => handleNoteChange(answerOption.answer, newValue)}
                disabled={readonly}
              />
            )}
            {showPhotoButton(worldState, question, answerOption.answer, inlineImaging) && (
              <TakePhotos
                count={getQuantity(worldState, question, answerOption.answer) || 1}
                onClick={() => handlePhotoButtonClick(worldState, question, answerOption.answer, onPhotoButtonClick)}
              />
            )}
          </React.Fragment>
        ))}
      </AnswerContainer>
      {showHelpText && identifiedBy.length > 0 && (
        <IdentifiedByText data-testid="identified-by-text">
          <label>{`Identified By ${identifiedBy.join(' and ')}`}</label>
        </IdentifiedByText>
      )}
      {!readonly && answerOptions?.some(a => a.disabledByAnnouncement) && (
        <HelpText data-testid="identified-by-help">
          {`Some answers have been disabled because of previously identified announcements by ${identifiedBy.join(
            ' and '
          )}.`}
        </HelpText>
      )}
      {showHelpText && selectedAnswers.length > 0 && removedAnnouncementsForJustThisQuestion.length > 0 && (
        <HelpText data-testid="announcements-removed-by-question">
          The chosen answer(s) will remove announcements
          <ul>
            {removedAnnouncementsForJustThisQuestion.map(a =>
              removedSourceLockedAnnouncements.includes(a) ? (
                <li style={{ color: 'red' }} key={a.code}>
                  {announcementDictionary.find(ann => ann.code === a.code)?.text} ({capitalize(a.source)})
                </li>
              ) : (
                <li key={a.code}>{announcementDictionary.find(ann => ann.code === a.code)?.text}</li>
              )
            )}
          </ul>
        </HelpText>
      )}
    </Container>
  );
};

const AnswerContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.xxs};
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.color.inactiveButtonBorder};
  border-radius: ${({ theme }) => theme.radius.lg};
  overflow: hidden;
  > * {
    border-top-width: 1px;
    border-top-color: black;
    border-top-style: solid;
  }
  > :first-child {
    border-top-style: none;
  }
`;
const StyledLink = styled.a`
  color: purple;
`;

const QuestionMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.space.xs};
  margin: ${({ theme }) => theme.space.xs};
  background-color: ${({ theme }) => theme.color.activeContainerBackground};
`;

const QuestionMessageText = styled.div`
  color: ${({ theme }) => theme.color.checkboxColor};
  margin-top: ${({ theme }) => theme.space.xs};
`;

const InfoImage = styled(InfoCircle)`
  padding: ${({ theme }) => theme.space.xs};
  color: ${({ theme }) => theme.color.checkboxColor};
  height: 18px;
  width: 18px;
`;

const InfoImageDivContainer = styled.div`
  margin-right: ${({ theme }) => theme.space.sm};
`;

const MultiTip = styled.p`
  color: ${({ theme }) => theme.color.disabledButtonText};
`;

const QuestionText = styled.label`
  font-weight: bold;
`;

const IdentifiedByText = styled.label`
  color: ${({ theme }) => theme.color.disabledButtonText};
  padding-top: ${({ theme }) => theme.space.sm};
`;

type ContainerProps = { theme: DefaultTheme; questionIsValid?: boolean };
const Container = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.space.lg};
  padding-left: ${({ theme }) => theme.space.sm};
  padding-top: ${({ theme }) => theme.space.sm};
  border-left: ${({ theme }) => theme.space.xs} solid transparent;
  padding-right: ${({ theme }) => theme.space.sm};
  border-right: ${({ theme }) => theme.space.xs} solid transparent;
  border-left-color: ${(props: ContainerProps) =>
    props.questionIsValid ? props.theme.color.progressIndicator : props.theme.color.primaryButtonBackground};
  background-color: ${(props: ContainerProps) => props.theme.color.activeContainerBackground};
  &.answered {
    border-left-color: transparent;
    background-color: transparent;
  }
`;

const QuestionHelpText = styled.p``;
